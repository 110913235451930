import React, { useState, useEffect, useRef } from 'react';
import AppLayout from '@layout/app';
import { Button, Col, Descriptions, Divider, Modal, PageHeader, Row, Select, Typography, Form, Input, Radio, Spin, message } from 'antd';
import { APPLICATION_TYPES } from '../../constants/general';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { IAppState } from '@redux/reducers';
import { clearAll } from '@utils/userAuth';
import Axios from 'axios';
import { ITeamPlayer } from 'src/schemas/ITeam';

function Entry(props: any) {
  const history = useHistory();
  const [typeId, setTypeId] = useState(0);
  const [APPLICATION_TYPES, setAPPLICATION_TYPES] = useState<any>([]);
  const [visible, setvisible] = useState(false);
  const [federations, setfederations] = useState<any>();
  const [loading, setloading] = useState(false);
  const [confirm, setconfirm] = useState(false)
  const fideRef: any = useRef();
  const [player, setplayer] = useState<any>();

  const fetchApplicationTypes = async () => {


    setloading(true);
    const { data } = await Axios.get("/application-types");
    console.log(data, "applications")
    setAPPLICATION_TYPES(props?.user?.id == 289 ? data : data.filter((d: any) => d.id != 10));
    const federations = await Axios.get("/federations");
    setloading(false);

    console.log(data, federations.data, "types")
    setfederations(federations.data)

  }
  useEffect(() => {
    console.log(props.user, "asdfajks")

    fetchApplicationTypes()

  }, [])

  const searchPlayer = async () => {
    console.log(props.playerType, "player type")
    if (fideRef.current.state.value == "") {
      return;
    }
    console.log(fideRef.current);
    setloading(true);
    try {

      const { data } = await Axios.get(
        `fide/players?request_id=9993985674&player_id=${fideRef.current.state.value
        }`
      );
      console.log(data, "dfata")
      setloading(false);
      if (data.length == 0) {
        message.error("no user found")

      }
      console.log(data, "data")
      setplayer(data[0]);
    } catch (error) {
    }
  };


  const handleCreateApplication = async (player: ITeamPlayer) => {
    const { data } = await Axios.post(`applications`, { type_id: typeId, players: [player] });
    history.push(`/applications/${data.application.id}/upload-documents`, data);
  }


  const openForm = () => {
    const [form] = Form.useForm();

    return <Modal
      visible={visible}
      title={`Create a New ${APPLICATION_TYPES.find((a: any) => a.id == typeId)?.name} Application`}
      okText="Create"
      cancelText="Cancel"
      onCancel={() => setvisible(false)}
      confirmLoading={confirm}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            setvisible(false);
            console.log(values, "vaklursd")
            values.fide_id = values?.fide_id || 0;
            values.rating = values.rating || 0;
            values.name = `${values.first_name}|${values.last_name}`;
            values.rank = 0;
            values.federation_id = props?.user?.federation_id
            delete values.first_name;
            delete values.last_name;
            setconfirm(true);
            handleCreateApplication(values);
            setconfirm(false)

          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      {typeId == 3 ?
        <Form
          name="add-players-form"
          form={form}
          autoComplete="off"
          style={{ margin: '20px 0', width: '100%' }}
        >
          <Row>
            <Col span={16}>
              <Form.Item label="Search your FIDE ID" name="fide_id">
                <Input ref={fideRef}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
              </Form.Item>
            </Col>

            <Col offset={1} span={2} >
              <Button loading={loading} onClick={searchPlayer} type="primary">
                Add
              </Button>
            </Col>

          </Row>
          <Divider />


          {player ?
            <Row key={"1"} justify="space-between">
              <Form.Item
                label="FIDE ID" name="fide_id"
                rules={[{ required: true, message: 'Please add FIDE Id ', }]}
                initialValue={player?.id_number}>
                <Input readOnly style={{ width: 300 }} />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="first_name"
                initialValue={player?.name?.split(",")[0]}
                rules={[{ required: true, message: 'Please input first name', }]}
              >
                {/* <Input /> */}
                <Input readOnly style={{ width: 300 }} />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
                initialValue={player?.name?.split(",")[1]}
              >
                <Input readOnly style={{ width: 300 }} />

              </Form.Item>
              <Form.Item label="Federation" name="federation_id"

                rules={[{ required: true, message: 'Please Select Federation', }]}

                initialValue={federations?.find((f: any) => f.country_code === player.country)?.id || ""}
              >
                <Select style={{ width: 300 }} >
                  {federations?.map((f: any) =>
                    <Select.Option value={f.id}>{f?.country_code}</Select.Option>
                  )}

                </Select>
              </Form.Item>
              <Form.Item label="Gender" name="gender" initialValue={player?.sex}>
                <Select style={{ width: 300 }}>
                  <Select.Option value={'M'}>M</Select.Option>
                  <Select.Option value={'F'}>F</Select.Option>
                </Select>
              </Form.Item>

            </Row> :
            <Row key="2" justify="space-between">
              <div style={{ marginBottom: 20 }}>If you don't have FIDE ID, Please add manually</div>

              <Form.Item
                label="First Name"
                name="first_name"

                rules={[{ required: true, message: 'Please input first name', }]}
              >
                {/* <Input /> */}
                <Input style={{ width: 300 }} />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
              >
                <Input style={{ width: 300 }} />

              </Form.Item>

              <Form.Item label="Federation" name="federation_id"
                rules={[{ required: true, message: 'Please Select Federation', }]}
              >
                <Select style={{ width: 300 }} >
                  {federations?.map((f: any) =>
                    <Select.Option value={f.id}>{f?.country_code}</Select.Option>
                  )}

                </Select>
              </Form.Item>

              <Form.Item label="Gender" name="gender" >
                <Select style={{ width: 300 }}>
                  <Select.Option value={'M'}>M</Select.Option>
                  <Select.Option value={'F'}>F</Select.Option>
                </Select>
              </Form.Item>

            </Row>
          }

        </Form> :
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
        >
          <Form.Item
            name="first_name"
            label="First Name"
            initialValue={props.user?.first_name}
            rules={[{ required: true, message: 'Please input first name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name" label="Last Name"
            initialValue={props.user?.last_name}
            rules={[{ required: true, message: 'Please input last name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="federation_id" label="Federation"
            rules={[{ required: true, message: 'Please input federation' }]}
            initialValue={federations?.find((f: any) => f.id == props.user?.federation_id)?.name}
          >
            <Input />
          </Form.Item>
          <Form.Item name="gender" label="Gender"
            rules={[{ required: true, message: 'Please select gender' }]}
          >
            <Radio.Group>
              <Radio value="M">Male</Radio>
              <Radio value="F">Female</Radio>
            </Radio.Group>
          </Form.Item>

        </Form>
      }
    </Modal>

  }


  const handleSubmit = async () => {
    const type = APPLICATION_TYPES.find((i: any) => i.value == typeId);
    console.log(type, 'type we are getting');
    if (typeId == 1 || typeId == 2) {
      history.push(`/teams/entry`, {
        type: APPLICATION_TYPES.find((i: any) => i.id == typeId)
      });
      return;
    }

    setvisible(true);
    return;


    history.push(`/teams/individual-register`, {
      type: APPLICATION_TYPES.find((i: any) => i.id == typeId)
    });
    return;
  };

  const onChange = (value: any) => {
    setTypeId(value);
  };

  return (
    <AppLayout>
      <PageHeader
        style={{ padding: 0 }}
        ghost={false}
        // onBack={() => window.history.back()}
        title={`${props?.user?.first_name} ${props?.user?.last_name !== null ? props.user?.last_name : ""}`}
        subTitle="Logged in"
        extra={[
          <>
            <Button key="1">View entries</Button>
            <Button key="1" type="primary" onClick={() => {
              clearAll();
              history.push('/');
            }}>
              LogOut
            </Button>
          </>
        ]}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Email">{props?.user?.email}</Descriptions.Item>
          <Descriptions.Item label="Phone">{props?.user?.phone}</Descriptions.Item>

        </Descriptions>
      </PageHeader>
      <Divider />
      <Typography>
        <Typography.Title level={4}>Instructions</Typography.Title>
        <Typography.Paragraph>
          Please choose the appropriate registration type to add an entry for Chess Olympiad 2022.
          <li>Open Section - To register the Open Team</li>
          <li>Women Section - To register the Womens Team</li>

        </Typography.Paragraph>
        <Divider />
      </Typography>
      <Row style={{ marginTop: 20 }}>


        <Col xs={24} sm={24} md={8} lg={8} xl={8} key={1}>
          <label>Choose registration type</label>
          <Spin spinning={loading}>
            <Select
              placeholder="Select registration type"
              style={{ width: '100%', marginTop: 20 }}
              // inputValue={`${typeId}`}
              onChange={onChange}
            >
              {
                props.user.id != 289 ?
                  props.user?.type_id == 1 ?

                    APPLICATION_TYPES.filter((a: any) => (a.id != 4) && (a.id != 8)).map((i: any) => (
                      <Select.Option value={i.id}>{i.name}</Select.Option>
                    )) :
                    APPLICATION_TYPES.filter((i: any) => i.id == props.user?.type_id).map((f: any) => (
                      <Select.Option value={f.id}>{f.name}</Select.Option>

                    )) :
                  APPLICATION_TYPES.map((a: any) => (
                    <Select.Option value={a.id}>{a.name}</Select.Option>
                  ))
              }
            </Select>
          </Spin>
        </Col>
      </Row>
      <Button type="primary" size="large" style={{ margin: '20px 0' }} onClick={handleSubmit}>
        Add Entry
      </Button>
      {openForm()}
    </AppLayout>
  );
}

const mapStateToProps = ({ user }: IAppState) => {
  return {
    user: user?.data
  };
};

export default connect(mapStateToProps)(Entry);

