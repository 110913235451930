import React, { useEffect, useState } from 'react';
import { Button, Table, Tabs, message, Col, Upload, Tag, Typography, Divider, Descriptions, PageHeader, Card, Row, Space, Spin, Select, Alert } from 'antd';
import AppLayout from '@layout/app';
import { CheckCircleFilled, ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { API_URL } from '../../constants/general';
import axios from 'axios';
import { ITeam, ITeamPlayer } from '../../schemas/ITeam';
import HealthDetail from '@components/Health';
import TravelDetail from '@components/travel';
import PersonalDetail from '@components/personal';
import PassportDetail from '@components/Passport';
import Accomodation from '@components/accomodation';
import { connect } from 'react-redux';
import { IAppState } from '@redux/reducers';
import { IUser } from 'src/schemas/IUser';
import { useSection } from './hooks';
import CongressPosition from '@components/CongressPosition';
import InsuaranceDetail from '@components/insuarance';
const { TabPane } = Tabs;

function makeApplicationPayload(user: any, type_id: number) {
  const player = {
    name: [user.first_name, user.last_name].join('|'),
    rank: 0,
    gender: 'M',
    rating: 0,
    federation_id: user.federation_id
  }
  return {
    type_id,
    players: [player]
  }
}

export interface IPlayerDoc extends ITeamPlayer {
  data: any;

}
export const getFullName = (name: string) => {
  console.log(name, "asdfkj")
  return `${name.split("|")[0]} ${!name.split("|")[1].includes("undefined") ? name.split("|")[1] : ""}`

}

function UploadDocument(props: any) {
  const history = useHistory();
  const { state }: any = useLocation();
  const [application, setapplication] = useState<any>()
  const [validated, setvalidated] = useState<string[]>([]);
  const [federation, setFederation] = useState<any>();


  const [teamPlayers, setTeamPlayers] = useState([] as Array<IPlayerDoc>);
  let { id }: any = useParams();
  const [loading, setLoading] = useState(false);


  // const {value , isLoading} =useSection(id||state.type.id,94)

  // console.log(value,isLoading ,state?.application?.type_id);



  useEffect(() => {
    getPlayers();
  }, [])



  useEffect(() => {
    console.log(application, "aopppli")

  }, [application, teamPlayers])



  const getPlayers = async () => {
    setLoading(true);
    const { data } = await axios.get(`applications/${id}`);

    const federations = await axios.get("federations");
    setFederation(federations.data);


    setapplication(data.application)
    console.log(data, data.application, "data got")
    setLoading(false);
    const players = data.players;
    handleTabChange(players[0].id, players)
    setLoading(false);
  }



  const onFinish = (values: any) => {
    const data = {
      ...values
    };
    handleSubmitForm(data);
  };

  const handleTabChange = async (playerId: string, players?: any) => {
    const player = players ? ([...players]) : ([...teamPlayers]);
    const index = player.findIndex(p => p.id == playerId);
    if (index == -1) {
      return;
    }
    setLoading(true);
    const { data } = await axios.get(`/applications/${id}/players/${playerId}`);
    console.log(data, "data")
    const val = [...validated];
    if (data.data.personel) {
      if (!val.find(v => v == playerId)) {

        val.push(playerId);
        setvalidated(val);
      }

    }


    player[index] = { ...data.player, data: data.data };
    console.log(player[index], "hello world")

    setTeamPlayers(player);
    setLoading(false);


  }
  const handleUpdate = (playerId: string, data: any, type: string) => {
    const val = [...validated];
    if (type == "personel") {
      if (!val.find(v => v == playerId)) {

        val.push(playerId);
        setvalidated(val);
      }

    }

    const players = [...teamPlayers];

    const index = players.findIndex(p => p.id == playerId);
    if (index != -1) {
      players[index] = { ...players[index], data: {} }

      players[index].data[type] = data;
    }

  }

  const handleSubmitForm = async (values: JSON) => {
    console.log(values);
    return;
  };

  // 



  const handleNextPage = () => {
    history.push(`/team-preview/${application.id}`, { players: teamPlayers })
  }

  return (
    <AppLayout>
      <PageHeader
        style={{ padding: 0 }}
        ghost={false}
        onBack={() => window.history.back()}
        title={state?.type?.name}
        subTitle={"Registration"}
        extra={[]}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="App ID">{`#OLY2022-0${id}`}</Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Divider />
      <Tabs defaultActiveKey="1" onChange={() => { }} >
        <TabPane tab={<div style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}>General</div>} key="1" style={{ fontSize: 24 }}>
          {application?.type_id && application?.type_id > 2 ?
            <Spin spinning={loading}>
              <Typography>
                <Typography.Title level={4}>
                  <span>
                    {validated.find(v => v == teamPlayers[0]?.id) ? <CheckCircleFilled style={{ color: "#52C41A", marginLeft: 10 }} /> : <ExclamationCircleFilled style={{ color: "#FAAD14", marginLeft: 10 }} />}
                    Documents

                  </span>
                </Typography.Title>
              </Typography>
              {teamPlayers[0]?.data &&
                <>
                  <PersonalDetail key={teamPlayers[0].id} player={teamPlayers[0].data?.personel ? { ...teamPlayers[0]?.data?.personel, type_id: application.type_id, id: teamPlayers[0].id } : { ...teamPlayers[0], type_id: application.type_id, }}
                    readOnly={application?.type_id === 3}
                    agency={application?.type_id === 8}
                    federations={federation}
                    onSave={(data: any) => handleUpdate(teamPlayers[0].id, data, "personel")} />
                  <HealthDetail key={teamPlayers[0].id} data={{ ...teamPlayers[0]?.data?.health, id: teamPlayers[0].id }} onSave={(data: any) => handleUpdate(teamPlayers[0].id, data, "health")} />
                  <InsuaranceDetail key={teamPlayers[0].id} data={{ ...teamPlayers[0]?.data?.insuarance, id: teamPlayers[0].id }} onSave={(data: any) => handleUpdate(teamPlayers[0].id, data, "insuarance")} />

                  {
                    application?.type_id === 3 &&
                    <CongressPosition player={teamPlayers[0]} />

                  }
                  <TravelDetail key={`${teamPlayers[0].id}-arrival`} data={{ ...teamPlayers[0]?.data?.travel, id: teamPlayers[0].id }} onSave={(data: any) => handleUpdate(teamPlayers[0].id, data, "travel")} type="arrival" />
                  <TravelDetail key={teamPlayers[0].id} data={{ ...teamPlayers[0]?.data?.travel, id: teamPlayers[0].id }} onSave={(data: any) => handleUpdate(teamPlayers[0].id, data, "travel")} type="departure" />
                </>}
              {/* <Accomodation/> */}
              <Button disabled={validated.length !== teamPlayers.length} size="large" type="primary" style={{ width: '10vw', margin: 20 }} onClick={handleNextPage}>Next</Button>
              {validated.length !== teamPlayers.length &&
                <Alert type="warning" style={{ margin: 10 }} message="Please fill all the required fields before proceeding further. " showIcon />
              }
            </Spin> :
            <Spin spinning={loading}>
              <Tabs defaultActiveKey={teamPlayers[0]?.id} tabPosition="left" onChange={handleTabChange}>
                {teamPlayers?.map(p => (
                  <TabPane tab={
                    <span>
                      {validated.find(v => v == p.id) ? <CheckCircleFilled style={{ color: "#52C41A" }} /> : <ExclamationCircleFilled style={{ color: "#FAAD14" }} />}
                      {getFullName(p?.name)}
                    </span>}
                    key={p.id} >
                    <Typography>
                      <Typography.Title level={4}>{getFullName(p.name)} Documents</Typography.Title>
                    </Typography>
                    {p?.data &&

                      <div style={{ height: "80vh", overflow: "scroll" }} key={p?.data?.personel || p?.data?.health ? `${p.id}-data` : p.id}>
                        <PersonalDetail player={p.data?.personel ? { ...p?.data?.personel, ...p } : p}
                          federations={federation}
                          onSave={(data: any) => handleUpdate(p.id, data, "personel")} />
                        <HealthDetail key={p.id} data={{ ...p?.data?.health, id: p.id }} onSave={(data: any) => handleUpdate(p.id, data, "health")} />
                        <InsuaranceDetail key={p.id} data={{ ...p?.data?.insuarance, id: p.id }} onSave={(data: any) => handleUpdate(p.id, data, "insuarance")} />

                        <TravelDetail key={`${p.id}-arrival`} data={{ ...p?.data?.travel, id: p.id }} onSave={(data: any) => handleUpdate(p.id, data, "travel")} type="arrival" />
                        <TravelDetail key={p.id} data={{ ...p?.data?.travel, id: p.id }} onSave={(data: any) => handleUpdate(p.id, data, "travel")} type="departure" />
                        <Button disabled={validated.length !== teamPlayers.length} size="large" type="primary" style={{ width: '10vw', margin: 20 }} onClick={handleNextPage}>Next</Button>
                        {validated.length !== teamPlayers.length &&
                          <Alert type="warning" style={{ margin: 10 }} message="Please fill all the required fields of all players before proceeding further. " showIcon />
                        }
                      </div>
                    }
                  </TabPane>
                ))}
              </Tabs>
            </Spin>
          }

        </TabPane>
        {application?.type_id < 3 &&
          <TabPane tab={<div style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}>Accommodation</div>} key="2">
            <Accomodation players={teamPlayers} />
          </TabPane>
        }
      </Tabs>
    </AppLayout>
  );
}


const mapStateToProps = ({ user }: IAppState) => {
  return {
    user: user.data
  };
};

export default connect(mapStateToProps)(UploadDocument);