// export const API_URL = 'http://127.0.0.1:8000/api/';
export const API_URL = 'https://api.chessolympiad.in/api/';
export const RZ_KEY = 'rzp_live_WRK0rd8dW3aZ3g';
export const RZ_SECRET = 'sM4DVP4uYY05Hn1RXPBu6UxZ';
export const MAX_PLAYER_ALLOW = 5;

export const APPLICATION_TYPES = [
  { value: 0, name: 'Open Section', type: 1 },
  { value: 1, name: 'Women Section', type: 1 },
  { value: 2, name: 'Arbiters', type: 12 },
  { value: 3, name: 'Visitors', type: 11 },
  { value: 4, name: 'Media', type: 6 },
  { value: 5, name: 'Congress Meetings', type: 2 }
];
