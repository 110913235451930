import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, Calendar, DatePicker, Upload, Radio, Select } from 'antd';
import AppLayout from '@layout/app';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '@components/loader/Loader';
import { connect } from 'react-redux';
import { IAppState } from '@redux/reducers';
import { UploadOutlined } from '@ant-design/icons';
import { upload_data } from '@utils/upload';
import { ITeamPlayer } from 'src/schemas/ITeam';
import { API_URL } from '@constants/general';
import ImgCrop from 'antd-img-crop';
import 'antd/es/modal/style';
import 'antd/es/slider/style';
import { useSection, useSectionUpdate } from './../../screens/register/hooks';
import moment from 'moment';



interface IProps {
  player: any,
  onSave?: (data: any) => void,
  readOnly?: boolean,
  agency?: boolean,
  federations?: any,
  media_types?: any

}

function PersonalDetail(props: IProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState(props.player)
  const [fileList, setFileList] = useState<any>(props.player.accredation ? [{ url: props.player.accredation }] : []);
  const [passportList, setPassportList] = useState<any>(props.player.passport_scan ? [{ url: props.player.passport_scan }] : []);


  const [form] = Form.useForm()


  const params: any = useParams();
  const { update, isLoading } = useSectionUpdate('personel', params.id, props.player.id);
  useEffect(() => {

    console.log(props.player, props.player.accredation, "player sajdfkjl")


  }, [props])



  const onFinish = async (values: any) => {
    console.log(values, "asdfkjsdkl")


    if (props.onSave) {
      if (values?.accredation[0]?.response?.file.url) {

        values.accredation = values?.accredation[0].response.file.url;
      }
      if (values.passport_scan[0]?.response?.file.url) {

        values.passport_scan = values.passport_scan[0].response.file.url;
      }

      await update(values)

      props?.onSave(values)
    }
  };

  const renderImageInfo = () => {
    console.log(fileList, "file")
    return < div style={{ display: "flex", flexDirection: "row" }}>
      <img src="https://www.schengenvisainfo.com/wp-content/uploads/2016/04/schengen_visa_photo_requirements.png" width="125" />
      <div style={{ marginLeft: 10, width: 500 }}>
        instructions for photo requirements:
        <ul>
          <li>
            JPEG, JPG file format only
          </li>
          <li>
            File size: not bigger than 1MB
          </li>
          <li>
            Each photo must be named in the format: COUNTRY - SURNAME – FIRST NAME e.g. Denmark - Smith – John
          </li>
          <li>
            Person must be photographed against a plain neutral background
          </li>
          <li>
            No other person should be visible in the photo
          </li>
        </ul>
      </div>
    </div>
  }

  const handleSubmitForm = async (values: JSON) => {
    console.log(values);
    return;
  };

  return (
    <Card key={player?.id} title="Personal Details" style={{ marginBottom: 20 }}>
      <Form layout="vertical" name="form_in_modal" onFinish={onFinish}>
        <Row justify={'space-between'} style={{ marginBottom: 40 }}>
          <Col span={18}>{renderImageInfo()}</Col>
          <Col span={6}>
            <Form.Item
              name="accredation"
              label="Photo for Accredation"
              rules={[{ required: true, message: 'Please upload photo of accredation' }]}
              initialValue={player?.accredation}

              getValueFromEvent={(e: any) => {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
            >
              <Upload
                name="file"
                accept="image/png, image/gif, image/jpeg, "
                headers={{
                  authorization: `${localStorage.getItem('token')}`
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={({ fileList: newFileList }: any) => {
                  console.log(fileList, newFileList)
                  setFileList(newFileList);
                }}
                action={`
                    ${API_URL}applications/${params?.id}/upload?player_id=${props.player.id}&type=image
                `}
                maxCount={1}
              >
                {fileList.length < 1 && '+ Upload'}
                {/* Upload */}

              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="first_name"
              label="First Name"
              initialValue={props?.player?.first_name || props?.player?.name}
              rules={[{ required: true, message: 'Please Input First Name' }]}


            >
              <Input readOnly={props?.readOnly} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="last_name"
              label="Last Name"
              initialValue={props?.player?.last_name?.includes("undefined") ? "" : props?.player?.last_name}
            // rules={[{ required: true, message: 'Please Input Last Name' }]}


            >
              <Input readOnly={props?.readOnly} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="fide_id"
              label="FIDE ID"
              initialValue={props.player.fide_id}
            // rules={[{ required: true, message: 'Please Input FIDE Id' }]}

            >
              <Input readOnly={props?.readOnly} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="citizenship"
              label="Citizenship"
              initialValue={props?.player?.citizenship}

            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="mobile"
              label="mobile"
              initialValue={props.player?.mobile}
              rules={[{ required: true, message: 'Please Input Mobile' }]}

            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="email"
              label="email"
              initialValue={props.player?.email}
              rules={[{ required: true, message: 'Please Input Email' }]}


            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item label="Federation" name="federation_id"

              rules={[{ required: true, message: 'Please Select Federation', }]}

              initialValue={parseInt(props?.player.federation_id) || ""}
            >
              <Select style={{ width: 300 }} >
                {props.federations?.map((f: any) =>
                  <Select.Option value={f.id}>{f?.country_code}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="dob"
              label="Date of Birth"
              initialValue={props.player.dob ? moment(props.player.dob) : undefined}
              rules={[{ required: true, message: 'Please Input Date of Birth' }]}

            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="birth_place"
              label="Place of Birth"
              initialValue={props?.player?.birth_place}
              rules={[{ required: true, message: 'Please Input place of birth' }]}

            >
              <Input />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="accredation"
              label="Photo for Accredation"
              rules={[{ required: true, message: 'Please upload photo of accredation' }]}
            >
              <Upload {...upload_data(props.player.id,"image",params?.id)}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="visa_on_arrival"
              label="Your Country citizenship requires an entry visa to India"
              rules={[{ required: true, message: 'Citizenship' }]}
              initialValue={props?.player?.visa_on_arrival}

            >
              <Radio.Group value={'yes'}>
                <Radio value={'yes'}>Yes</Radio>
                <Radio value={'no'}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="passport_issue_date"
              label="Passport Issue Date"
              initialValue={props?.player?.passport_issue_date ? moment(props?.player?.passport_issue_date) : undefined}
              rules={[{ required: true, message: 'Please Input passport issue date' }]}

            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="passport_expiry_date"
              label="Passport Expiry Date"
              initialValue={props.player.passport_expiry_date ? moment(props.player.passport_expiry_date) : undefined}
              rules={[{ required: true, message: 'Please Input Passport expiry date' }]}

            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="passport_no"
              label="Passport Number"
              rules={[{ required: true, message: 'Please Input Passport Number' }]}

              initialValue={props?.player?.passport_no}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              name="passport_scan"
              label={`Upload Scan copy of passport  ( Only Images are allowed )`}
              initialValue={props?.player?.passport_scan}
              rules={[{ required: true, message: "Please upload copy of passport scan" }]}

              getValueFromEvent={(e: any) => {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}

            >
              <Upload
                key="passport_scan"
                accept="image/png, image/gif, image/jpeg, .pdf, .doc"
                name="file"
                headers={{
                  authorization: `${localStorage.getItem('token')}`
                }}
                listType="picture-card"
                fileList={passportList}
                onChange={({ fileList: newFileList }: any) => {
                  console.log(fileList, newFileList)
                  setPassportList(newFileList);
                }}
                action={`
                    ${API_URL}applications/${params?.id}/upload?player_id=${props.player.id}&type=image
                `}
                maxCount={1}
              >
                {passportList.length < 1 && '+ Upload'}
                {/* Upload */}

              </Upload>
            </Form.Item>

          </Col>
          {player?.type_id == 8 &&
            <Form.Item name="media_types" label="Media Application Types" initialValue={props.player.media_types}>
              <Select style={{ width: 300 }} >
                <Select.Option value={"Official Brodcasters"}>Official Brodcasters</Select.Option>
                <Select.Option value={"Non-Rights holding television broadcasters"}>Non-Rights holding television broadcasters</Select.Option>
                <Select.Option value={"Photographers"}>Photographers</Select.Option>
                <Select.Option value={"Member of the written press"}>Member of the written press</Select.Option>
              </Select>
            </Form.Item>
          }
          {
            props?.agency &&

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                name="agency"
                label="Media Agency"
                initialValue={props?.player?.agency}
              >
                <Input />
              </Form.Item>
            </Col>
          }
        </Row>
        <Button type="primary" htmlType={"submit"} loading={isLoading}>Save</Button>
      </Form>
    </Card >
  );
}
// First Name, Last Name, FIDE ID,  Country, Photo, Mobile Number, Email, Remove Visa Upload

const mapStateToProps = ({ user }: IAppState) => {
  return {
    user: user.data
  };
};

export default PersonalDetail;
