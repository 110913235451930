import Loader from '@components/loader/Loader';
import TeamDetailDrawer from '@components/TeamDetail';
import AppLayout from '@layout/app';
import { Space, Table, Tag, Button, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ITeam } from '../../schemas/ITeam';

export default function Teams() {
  const [teams, setTeams] = useState([] as Array<ITeam>);
  const [selectedTeam, setSelectedTeam] = useState({} as ITeam);
  const [loading, setloading] = useState(false);
  const [atype, settype] = useState<any>()

  const [visible, setVisible] = useState(false);
  const history: any = useHistory();

  const getData = async () => {
    setloading(true);
    const { data } = await axios(`applications`);
    console.log(data, "entrie data")
    setloading(false);

    setTeams(data.data);
  };



  useEffect(() => {
    getData();
  }, []);

  const handleSelectTeam = (team: ITeam) => {
    setSelectedTeam(team);
    setVisible(true);
  };

  const columns = [
    {
      title: 'Application Type',
      dataIndex: 'type',
      key: 'type',
      render: (p: any) => <div>{p?.name}</div>

    },
    {
      title: 'Application ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: ITeam) => (
        <Space size="middle">
          <Link to={`teams/${record.id}/view`}>
            <Button type="link">#OLY2022-{text}</Button>
          </Link>
        </Space>
      )
    },


    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created: any) => moment(created).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Federation',
      dataIndex: 'federation',
      key: 'federation',
      render: (fed: any) => <div>{fed.name}</div>
    },
    {
      title: 'Status',
      dataIndex: 'statusLabel',
      key: 'statusLabel',
      render: (status: any) => status == "Draft" ? <Tag color="warning">{status}</Tag> : (status === "Approved" ? <Tag color="success">{status}</Tag> : <Tag color="geekblue">{status}</Tag>)
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: ITeam) => (
        <Space size="small" style={{ textAlign: "center" }}>

          <Button type="primary" onClick={() => {
            record.status == 0 ? (record?.type_id! > 2 ?
              history.push(`/applications/${record.id}/upload-documents`, record) :
              history.push(`teams/${record.id}/view`)) :
              history.push(`/applications/${record.id}/upload-documents`)
          }}>Update</Button>

        </Space>
      )
    }
  ];


  return (
    <AppLayout>
      <Spin spinning={loading}>
        {/*         
      <Button
        type="primary"
        style={{ marginBottom: 20 }}
        onClick={() => history.push('/teams/entry')}
      >
        Add Team
      </Button> */}
        <Table columns={columns} dataSource={teams} />
        <TeamDetailDrawer team={selectedTeam} visible={visible} setVisible={setVisible} />
      </Spin>
    </AppLayout>
  );
}

const types_arr = [
  'Open Section',
  'Women Section',
  'General Assembly',
  'Visitors',
  'Federation Officials',
  'Congress Meetings'
];
