import React, { useState, useEffect } from 'react';
import { Card, Space, Select, Button, Alert, message } from 'antd';
import { useParams } from 'react-router-dom';
import { useSectionUpdate } from './../../screens/register/hooks';
import { IAppState } from '@redux/reducers';
import { connect } from 'react-redux';


function CongressPosition(props: any) {
  const params: any = useParams();
  const { update, isLoading } = useSectionUpdate('positions', params.id, props.player.id);
  console.log(props.player.data?.positions?.positions.split('|'), "fakjsndfklsbfnasndKfjl")
  let pos = props.player.data?.positions ? props.player.data?.positions?.positions.split('|') : [];
  pos.pop()
  const [positions, setPosition] = useState<number[]>(pos ? pos.map((p: any) => parseInt(p)) : []);



  const saveHandler = () => {

    let data = "";
    positions?.map(p => data += `${p}|`)
    console.log(data, "hello")
    update({ positions: data })

  }

  console.log(props.user, "user askjdfk")

  const options = [
    { id: 1, name: 'FIDE Council' },
    { id: 2, name: 'FIDE President' },
    { id: 3, name: 'FIDE Management Board' },
    { id: 4, name: 'FIDE Delegate' },
    { id: 5, name: 'FIDE Zonal Council' },
    { id: 6, name: 'FIDE Commission Member' },
    { id: 7, name: 'FIDE Commissions Chairperson' },
    { id: 8, name: 'FIDE Official' },
    { id: 9, name: 'FIDE Affiliated Organization' },
    { id: 10, name: 'FIDE Counselor' },
    { id: 11, name: "Presidential Ticket (Team Kouatly)" },
    { id: 12, name: "Presidential Campaign Member (Team Kouatly)" },
    { id: 13, name: "Presidential Ticket (Team Baryshpolets)" },
    { id: 14, name: "Presidential Campaign Member (Team Baryshpolets)" },
    { id: 15, name: "Presidential Ticket (Team Cheripov)" },
    { id: 16, name: "Presidential Campaign Member (Team Cheripov)" },
    { id: 17, name: "Presidential Ticket (Team Dvorkovich)" },
    { id: 18, name: "Presidential Campaign Member (Team Dvorkovich)" },
    { id: 19, name: "GUEST" },
    { id: 20, name: "VVIP" },
    { id: 21, name: "VIP" }

  ];

  return (
    <Card style={{ marginTop: 30 }}>
      <Space>
        Select Position :
        <Select
          style={{ width: 300 }}
          onChange={(selected: any) => selected.length <= 3 ? setPosition(selected) : message.error("You can add only 3 positions")}
          mode="multiple"
          defaultValue={pos.map((pl: any) => parseInt(pl)) || []}
          value={positions}
        >
          {props.user.id == 285 || props.user.type_id == 3 ?
            options.map(o => (
              <Select.Option value={o.id}>{o.name}</Select.Option>
            )) :
            <Select.Option value={4}>National Delgate</Select.Option>


          }
        </Select>
      </Space>
      <Button type="primary" loading={isLoading} style={{ marginLeft: 20 }} onClick={saveHandler}>Save</Button>
      <Alert style={{ marginTop: 20 }} message="Only three positions can be selected" />
    </Card>
  );
}

const mapStateToProps = ({ user }: IAppState) => {
  return {
    user: user.data
  };
};

export default connect(mapStateToProps)(CongressPosition);
