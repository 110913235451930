import Axios from "axios";
import { useState, useEffect } from "react";

interface IPlayer {
    name: string;
}
interface ISectionData {
    [key: string]: Record<string, any>;
}

interface ISection {
    player: IPlayer,
    data: ISectionData;
}

export function useSection<T = ISection>(applicationId: number, playerId: number) {
    const [isLoading, setLoading] = useState(false)
    const [value, setPayload] = useState<T>({} as any);
    useEffect(() => {
        (async () => {
            setLoading(true);
            const {data} = await Axios.get(`/applications/${applicationId}/players/${playerId}`);
            setLoading(false);
            setPayload(data);
        })()
    }, []);
   
    return {
        value,
        isLoading,
    }
}


export function useSectionUpdate(name: string, applicationId: string, playerId: string) {
    const [isLoading, setLoading] = useState(false)
   
    async function update(payload: Record<string, any>) {
        setLoading(true);
        const {data} = await Axios.put(`/applications/${applicationId}/players/${playerId}`, {data: payload, group: name})
        setLoading(false);
    }
    return {
        update,
        isLoading,
    }
}