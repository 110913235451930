import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, message, TimePicker, Radio, Card, Row, Col, Alert } from 'antd';
import AppLayout from '@layout/app';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '@components/loader/Loader';
import { connect } from 'react-redux';
import { IAppState } from '@redux/reducers';
import { useSection, useSectionUpdate } from './../../screens/register/hooks';
import moment from 'moment';

function TravelDetail(props: any) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const params = useParams<any>();
  const [data, setdata] = useState();
  const { update, isLoading } = useSectionUpdate('travel', params.id, props.data.id);

  useEffect(() => {
    console.log(props, "asdfjhshjksdhbahjfk")

  }, [props])



  const onFinish = async (values: any) => {
    if (props.type == "arrival") {
      values.arrival_from_city = values?.arrival_from?.city;
      values.arrival_to_city = values?.arrival_to?.city;
      values.arrival_from_country = values?.arrival_from?.country;
      values.arrival_to_country = values?.arrival_to?.country;
      delete values?.arrival_from
      delete values?.arrival_to

    } else {

      values.departure_from_city = values?.departure_from?.city;
      values.departure_to_city = values?.departure_to?.city;
      values.departure_from_country = values?.departure_from?.country;
      values.departure_to_country = values?.departure_to?.country;
      delete values?.departure_from
      delete values?.departure_to


    }

    await update(values)
    props.onSave(values);
  };

  const handleSubmitForm = async (values: JSON) => {
    return;
  };

  return (
    <Card
      style={{ marginTop: 20 }}
      title={props.type == 'arrival' ? 'Arrival Details' : 'Departure Details'}
    >
      {props.type == 'arrival' ? (
        <Form layout="vertical" key="arrival" name="form_in_modal" onFinish={onFinish}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="date_arrival"
                label="Date and Time of arrival flight"
                // rules={[{ required: true, message: 'Please input Airport name' }]}
                initialValue={props?.data?.date_arrival ? moment(props?.data?.date_arrival) : moment()}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                label="Arrival from"

              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input.Group>
                  <Form.Item
                    name={[
                      "arrival_from", "city"
                    ]}
                    initialValue={props?.data?.arrival_from_city}
                  >
                    <Input prefix="city :" />
                  </Form.Item>
                  <Form.Item
                    name={[
                      "arrival_from", "country"
                    ]}
                    initialValue={props?.data?.arrival_from_country}

                  >

                    <Input prefix="country :" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                label="Arrival to"

              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input.Group>
                  <Form.Item
                    name={[
                      "arrival_to", "city"
                    ]}
                    initialValue={props?.data?.arrival_to_city || "Chennai"}
                  >
                    <Input prefix="city :" />
                  </Form.Item>
                  <Form.Item
                    name={[
                      "arrival_to", "country"
                    ]}
                    initialValue={props?.data?.arrival_to_country || "India"}
                  >
                    <Input prefix="country :" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="arrival_flight_number"
                label="Arrival Flight Number"
                initialValue={props?.data?.arrival_flight_number}

              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="arrival_flight_company"
                label="Arrival Flight Company"
                initialValue={props?.data?.arrival_flight_company}

              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item name="arrival_comments" label="Comments"
                initialValue={props?.data?.arrival_comments}

              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" loading={isLoading} htmlType="submit">Save</Button>
          <Alert
            message="Arrival details can be added later after submitting the application."
            type="info"
            showIcon
            style={{ marginTop: 20 }}
          />
        </Form>
      ) : (
        <Form layout="vertical" key="departure" name="form_in_modal" onFinish={onFinish}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="date_departure"
                initialValue={props?.data?.date_departure ? moment(props?.data?.date_departure) : moment()}
                label="Date and Time of departure flight"
              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                label="Departure from"

              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input.Group>
                  <Form.Item
                    name={[
                      "departure_from", "city"
                    ]}
                    initialValue={props?.data?.arrival_from_city || "Chennai"}
                  >
                    <Input prefix="city :" />
                  </Form.Item>
                  <Form.Item
                    name={[
                      "departure_from", "country"
                    ]}
                    initialValue={props?.data?.arrival_from_country || "India"}

                  >

                    <Input prefix="country :" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="departure_to"
                label="Departure to"
              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input.Group>
                  <Form.Item
                    name={[
                      "departure_to", "city"
                    ]}
                    initialValue={props?.data?.departure_to_city}
                  >
                    <Input prefix="city :" />
                  </Form.Item>
                  <Form.Item
                    name={[
                      "departure_to", "country"
                    ]}
                    initialValue={props?.data?.departure_to_country}
                  >
                    <Input prefix="country :" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="departure_flight_number"
                label="Departure Flight Number"
                initialValue={props?.data?.departure_flight_number}

              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item
                name="departure_flight_company"
                label="Departure Flight Company"
                initialValue={props?.data?.departure_flight_company}
              // rules={[{ required: true, message: 'Please input Airport name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <Form.Item name="departure_comments" label="Comments" initialValue={props.data?.departure_comments}>
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Button loading={isLoading} type="primary" htmlType="submit">
            Save
          </Button>
          <Alert
            message="Departure details can be added later after submitting the application."
            type="info"
            showIcon
            style={{ marginTop: 20 }}
          />
        </Form>
      )}
    </Card>
  );
}

const mapStateToProps = ({ user }: IAppState) => {
  return {
    user: user.data
  };
};

export default connect(mapStateToProps)(TravelDetail);

