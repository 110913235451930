import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Upload, message, Card, Col, Row, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { IAppState } from '@redux/reducers';
import { UploadOutlined } from '@ant-design/icons';
import { useSectionUpdate } from './../../screens/register/hooks';
import moment from 'moment';
import { API_URL } from '@constants/general';

function HealthDetail(props: any) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState<any>(props.data);
  const params: any = useParams();
  const [fileList, setFileList] = useState<any>(props.data?.rtpcr ? [{ url: props.data?.rtpcr }] : []);

  const { update, isLoading } = useSectionUpdate('health', params.id, props.data.id);
  console.log(props, 'key');

  useEffect(() => {
    setdata(props.data);

  }, [props])

  const onFinish = async (values: any) => {
    const data = {
      ...values
    };
    console.log(values, "val")
    if (values?.rtpcr && (values?.rtpcr[0]?.response?.file?.url)) {

      values.rtpcr = values.rtpcr[0].response.file.url
    }

    handleSubmitForm(data);
    await update(values);
    props.onSave(values);
  };

  const handleSubmitForm = async (values: JSON) => {
    console.log(values);
    return;
  };

  return (
    <Card title="Health Records" style={{ marginTop: '2rem' }} >
      <div style={{ marginTop: 20, marginBottom: 40, fontSize: 14 }}>
        All the delegation members arriving for 44th World Chess Olympiad will be medically insured by the organizers. All are requested to wear mask, ensure social distancing and hand sanitization.
        <br />
        Further, all are required to register at Air Suvidha Portal (<a href="https://www.newdelhiairport.in">https://www.newdelhiairport.in</a>- /airsuvidha/apho-registration) before commencement of their journey. Fully vaccinated persons are exempted from submitting RT PCR test report.

      </div>
      <Form layout="vertical" name="form_in_modal" onFinish={onFinish}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item name="result" label="RT-PCR Result" initialValue={data?.result} >
              <Select>
                <Select.Option value="negative">Negative</Select.Option>
                <Select.Option value="positive">Positive</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item name="issue_at" label="Date of RT-PCR" initialValue={data?.issue_at ? moment(data?.issue_at) : moment()}>
              <DatePicker />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item name="rtpcr" label="Upload Negative RTPCR test Report / Fully vaccinated certificate"
              // rules={[{ required: true, message: "Please upload rtpcr report" }]}
              initialValue={data?.rtpcr}

              getValueFromEvent={(e: any) => {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}

            >
              <Upload
                accept="image/png, image/gif, image/jpeg, .pdf, .doc"


                name="file"
                headers={{
                  authorization: `${localStorage.getItem('token')}`
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={({ fileList: newFileList }: any) => {
                  console.log(fileList, newFileList)
                  setFileList(newFileList);
                }}
                action={`
                    ${API_URL}applications/${params?.id}/upload?player_id=${data.id}&type=image
                `}
                maxCount={1}

              >
                {fileList.length < 1 && '+ Upload'}

              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item name="comments" label="Comments" initialValue={data?.comments}>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" loading={isLoading} htmlType="submit">
          Save
        </Button>
      </Form>
    </Card>
  );
}
// First Name, Last Name, FIDE ID,  Country, Photo, Mobile Number, Email, Remove Visa Upload

const mapStateToProps = ({ user }: IAppState) => {
  return {
    user: user.data
  };
};

export default HealthDetail;

const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text'
  },
  onChange(info: any) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};
