import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, Upload, message, Card, Col, Row, Select, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { IAppState } from '@redux/reducers';
import { useSectionUpdate } from './../../screens/register/hooks';

function InsuaranceDetail(props: any) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState<any>(props.data);
    const params: any = useParams();

    const { update, isLoading } = useSectionUpdate('insuarance', params.id, props.data.id);

    useEffect(() => {
        setdata(props.data);
        console.log(props.data, "dataSDf")

    }, [props])

    const onFinish = async (values: any) => {
        const data = {
            ...values
        };
        console.log(values, "values");

        handleSubmitForm(data);
        await update(values);
        props.onSave(values);
    };

    const handleSubmitForm = async (values: JSON) => {
        console.log(values);
        return;
    };

    return (
        <Card title="Insuarance Details" style={{ marginTop: '2rem' }} >

            <Form layout="vertical" name="form_in_modal" onFinish={onFinish}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="blood_group" label="Blood Group" initialValue={data?.blood_group}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="identification_mark" label="Identification Mark" initialValue={data?.identification_mark}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="height" label="Height(cm)" initialValue={data?.height}>
                            <InputNumber min={0} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="weight" label="Weight(kg)" initialValue={data?.weight}>
                            <InputNumber min={0} />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="food_allergy" label="Food Allergy" initialValue={data?.food_allergy}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="drug_allergy" label="Drug Allergy" initialValue={data?.drug_allergy}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="medications" label="Medications (If any)" initialValue={data?.medications}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="disease" label="Pre-existing Disease " initialValue={data?.disease}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="medical_history" label="Any Significaant Medical History" initialValue={data?.medical_history}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                        <Form.Item name="vacination_status" label="Covid Vaccination Status" initialValue={data?.vacination_status} >
                            <Select>
                                <Select.Option value="1">Dose 1</Select.Option>
                                <Select.Option value="2">Dose 2</Select.Option>
                                <Select.Option value="booster">Booster Dose</Select.Option>

                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Button type="primary" loading={isLoading} htmlType="submit">
                    Save
                </Button>
            </Form>
        </Card>
    );
}
// First Name, Last Name, FIDE ID,  Country, Photo, Mobile Number, Email, Remove Visa Upload



export default InsuaranceDetail;

