import { CheckOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import AppLayout from '@layout/app';
import { Button, Checkbox, Descriptions, Divider, PageHeader, Space, Spin, Table, Typography } from 'antd';
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { ITeam, ITeamPlayer } from '../../schemas/ITeam';
import { getFullName } from './document';

interface ILState {
  team: ITeam;
  players: Array<ITeamPlayer>
}
const options =
  [
    { id: 1, name: 'FIDE Council' },
    { id: 2, name: 'FIDE President' },
    { id: 3, name: 'FIDE Management Board' },
    { id: 4, name: 'FIDE Delegate' },
    { id: 5, name: 'FIDE Zonal Council' },
    { id: 6, name: 'FIDE Commission Member' },
    { id: 7, name: 'FIDE Commissions Chairperson' },
    { id: 8, name: 'FIDE Official' },
    { id: 9, name: 'FIDE Affiliated Organization' },
    { id: 10, name: 'FIDE Counselor' },
    { id: 11, name: "Presidential Ticket (Team Kouatly)" },
    { id: 12, name: "Presidential Campaign Member (Team Kouatly)" },
    { id: 13, name: "Presidential Ticket (Team Baryshpolets)" },
    { id: 14, name: "Presidential Campaign Member (Team Baryshpolets)" },
    { id: 15, name: "Presidential Ticket (Team Cheripov)" },
    { id: 16, name: "Presidential Campaign Member (Team Cheripov)" },
    { id: 17, name: "Presidential Ticket (Team Dvorkovich)" },
    { id: 18, name: "Presidential Campaign Member (Team Dvorkovich)" },
    { id: 19, name: "GUEST" },
    { id: 20, name: "VVIP" },
    { id: 21, name: "VIP" }
  ];

export default function PreviewReg() {
  const { state }: any = useLocation();
  const [playerTypes, setplayerTypes] = useState<any>({});
  const history = useHistory();
  const params: any = useParams();
  const [application, setapplication] = useState<any>();
  const [players, setplayers] = useState<any>();

  const handleSubmit = async () => {
    const { data } = await Axios.post(`applications/${players[0].application_id}/submit`);
    console.log("submitted data", data)
    history.push(`/registration-status/${players[0]?.application_id}`);

  }

  const fetchPlayerTypes = async () => {
    const { data } = await Axios.get(!params?.id ? "player-types" : "application-types");
    console.log(data, "data");
    let playerTypes: any = {};
    data.map((d: any) =>
      playerTypes[`${d.id}`] = d.name
    )
    setplayerTypes(playerTypes)
  }

  const fetchApplications = async () => {
    const { data } = await Axios.get(`applications/${params?.id}`);
    console.log(data, "asdkfasldkfm")
    setapplication(data.application);
    if (data.application.type_id > 2) {
      let players = data.players.map((d: any) => ({ ...d, type_id: data.application?.type_id }))
      players?.map((p: any) => {
        p.data.map((d: any) => {
          p[`${d.name}`] = d.value;
        })
      })


      console.log(players, "sdfsdf", playerTypes)
      setplayers(players);
      return;

    }
    console.log(data.players, "sdfsdf", playerTypes)

    setplayers(data.players);

  }

  useEffect(() => {

    fetchPlayerTypes()
    if (params.id) {
      fetchApplications();
    } else {
      setplayers(state?.players);
    }


  }, [])




  let columns = [
    {
      title: 'Fide ID',
      dataIndex: 'fide_id',
      key: 'fide_id'
    },
    {
      title: 'Member Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <div>{getFullName(name)}</div>

    },
    {
      title: "Member Type",
      dataIndex: 'type_id',
      key: 'type_id',
      render: (p: any, record: any) => <div>{record?.type?.name || playerTypes[`${p}`]}</div>

    },
    {
      title: 'Gender',
      key: 'gender',
      dataIndex: 'gender'
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'rating'
    },
    {
      title: 'Board No.',
      key: 'rank',
      dataIndex: 'rank'
    }


  ];






  const docColumns = [
    {
      title: 'Fide ID',
      dataIndex: 'fide_id',
      key: 'fide_id'
    },
    {
      title: 'Member Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <div>{getFullName(name)}</div>
    },
    {
      title: 'Member Type',
      dataIndex: 'type_id',
      key: 'type_id',
      render: (p: any, record: any) => <div>{record?.type?.name || playerTypes[`${p}`]}</div>

    },

    {
      title: 'Photo',
      key: 'photo',
      render: (t: any) => t?.accredation || t?.data.find((d: any) => d.name == "accredation") ? <strong><CheckOutlined style={{ color: 'green' }} /></strong> : <strong><CloseOutlined style={{ color: 'red' }} /></strong>
    },
    {
      title: 'Passport',
      key: 'passport',
      render: (t: any) => t?.passport_scan || t?.data.find((d: any) => d.name == "passport_scan") ? <strong><CheckOutlined style={{ color: 'green' }} /></strong> : <strong><div >-</div></strong>
    },
    {
      title: 'Travel',
      key: 'travel',
      render: (t: any) => t?.travel || t?.data.find((d: any) => d.group == "travel") ? <strong><CheckOutlined style={{ color: 'green' }} /></strong> : <strong><div>-</div></strong>
    },
    {
      title: 'RT-PCR',
      key: 'pcr',
      render: (t: any) => t?.rtpcr || t?.data.find((d: any) => d.name == "rtpcr") ? <strong><CheckOutlined style={{ color: 'green' }} /></strong> : <strong><div>-</div></strong>
    },
    // {
    //     title: 'VISA',
    //     key: 'visa',
    //     render: (t:any) => <strong><CheckOutlined style={{color:'green'}}/></strong>
    // },
    // {
    //     title: 'Vaccine',
    //     key: 'vaccine',
    //     render: (t:any) => <strong><CheckOutlined style={{color:'green'}}/></strong>
    // },
  ];

  // return <div>hello world</div>


  return (
    <AppLayout>
      <PageHeader
        style={{ padding: 0, marginBottom: 20 }}
        ghost={false}
        title={players?.length > 1 ? "Preview Team" : "Preview Application"}
        subTitle=""
        extra={[
          <>
            <Button onClick={() => history.goBack()} key="1">Back/Edit</Button>
          </>
        ]}
      >
      </PageHeader>



      <Table loading={!players} columns={application?.type_id == 3 ? [...columns.filter(c => c.key !== "rating" && c.key !== "rank"), ({ title: "Position", key: "position", dataIndex: "position", render: (p, record) => <div>{record?.positions?.split("|").map((p: any, i: any) => i !== record?.positions?.split("|").length - 1 && `${options.find(o => o.id == p)?.name}, `)}</div> })] : columns} dataSource={players} pagination={false} />
      <Divider />
      <Typography.Title level={4}>Documents Detail</Typography.Title>

      <Table loading={!players} columns={docColumns} dataSource={players?.map((d: any) => ({ name: d.name, fide_id: d?.fide_id, ...d }))} pagination={false} />





      <div style={{ marginTop: 20 }} />
      {
        application?.status == 0 ?
          <>
            <Checkbox checked={true}>I Accept all term and conditions.</Checkbox>
            <div style={{ marginTop: 20 }} />
            <div style={{ marginTop: 20 }} />
            <Button type="primary" onClick={handleSubmit}>
              Submit Application
            </Button>
            <div style={{ marginTop: 50 }} />
          </>
          : null

      }

    </AppLayout>
  );
}
