import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  DatePicker,
  Upload,
  message,
  Card,
  Col,
  Row,
  Select,
  Divider,
  Badge,
  Table,
  Tag,
  Space
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { IAppState } from '@redux/reducers';
import { UploadOutlined } from '@ant-design/icons';
import { ITeamPlayer } from 'src/schemas/ITeam';
import { getFullName, IPlayerDoc } from './../../screens/register/document';
import './accomodation.css';
import moment, { Moment } from 'moment';
import PlayerDetailCard from './PlayerDetailCard';
import { useForm } from 'antd/lib/form/Form';
import { FormInstance } from 'rc-field-form';
import Axios from 'axios';
import { ColumnType } from 'antd/lib/table';

interface IData {
  id?: string;
  size: number;
  player1?: string | ITeamPlayer;
  player2?: string | ITeamPlayer;
  checked_in?: string;
  checked_out?: string;
  is_paid?: number;

}

interface IProps {
  players: IPlayerDoc[];
}

interface IForm {
  players: IPlayerDoc[];
  onSave: (acc: IData) => void;
  loading: boolean;
  current?: IData;
}

interface IAccProps {
  data: IData[];
  players: IPlayerDoc[];
  onDelete: (id: string) => void;
  onUpdate: (id: string) => void;
}

export default function Accomodation(props: IProps) {
  const history = useHistory();
  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);
  const [first, setType] = useState();
  const [player, setplayer] = useState(props.players);
  const [current, setCurrent] = useState<IData>();
  const [single, setsingle] = useState(2);
  const [double, setdouble] = useState(2);

  const fetchAccommodations = async () => {
    const { data } = await Axios.get(`applications/${params.id}/accommodations`);
    console.log(data, "dataskjd")
    setsingle(single - data.filter((d: any) => d.size == 1).length);
    setdouble(double - data.filter((d: any) => d.size == 2).length);
    let newPlayer = player.filter(p => !data?.player1?.find((pl: any) => pl.id == p.id));
    newPlayer = newPlayer.filter(p => !data?.player2?.find((pl: any) => pl.id == p.id));
    setplayer(newPlayer)




    setData(data);
  };
  useEffect(() => {
    fetchAccommodations();
  }, []);

  const params: any = useParams();

  const handleSave = async (values: IData) => {
    setLoading(true);
    values.size == 1 ? setsingle(single - 1) : setdouble(double - 1);
    values.is_paid = 0;
    if (values.size == 1) {
      const sin = single - 1;
      if (sin < 0) {
        values.is_paid = 1;
      }
      setsingle(sin)
    } else if (values.size == 2) {
      const dou = double - 1;
      if (dou < 0) {
        values.is_paid = 1;
      }
      setdouble(dou);
    }
    setplayer(player.filter(p => p.id !== values.player1 && p.id !== values.player2))
    const Data = await Axios.post(`applications/${params.id}/accommodations`, values);
    setLoading(false);
    setData([...data, Data.data]);
  };
  const handleDelete = async (id: string) => {
    console.log(id, "id");
    await Axios.delete(`applications/${params.id}/accommodations/${id}`);
    setData(data.filter(d => d?.id !== id));


  }
  const handleUpdate = (id: string) => {
    console.log(id, "id");
    setCurrent(data.find(d => d?.id == id))
  }

  return (
    <div>
      <AccomodationForm players={player} onSave={handleSave} loading={loading} current={current} />
      <AccomodationTable data={data} players={player} onDelete={handleDelete} onUpdate={handleUpdate} />
    </div>
  );
}

const AccomodationForm = (props: IForm) => {
  const form = useRef<FormInstance<IData> | null>(null);
  const [size, setsize] = useState(1);
  useEffect(() => {
    if (!form.current) {
      return;
    }
    if (props.current) {
      const edit = { ...props.current };
      console.log(props.current)
      form.current.setFieldsValue({
        size: edit.size, player1: (edit?.player1 as ITeamPlayer)?.id,
        player2: (edit?.player2 as ITeamPlayer)?.id,
        checked_in: moment(edit?.checked_in) as any,
        checked_out: moment(edit?.checked_out) as any
      })
    }
    console.log(form.current.getFieldValue('size'));
  }, [props]);
  const handleFinish = (values: IData) => {
    console.log(values, "values");
    if (values.size == 1) {
      values.player2 = undefined;
    }
    props.onSave(values);

    form.current!.resetFields();
  };
  const handleChange = (val: any) => {
    setsize(val);
  };
  return (
    <Form
      onFinish={handleFinish}
      onChange={handleChange}
      ref={f => {
        form.current = f;
      }}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="size"
            label="Room Type"
            initialValue={1}
            rules={[{ required: true, message: 'Please select room type' }]}
          >
            <Select onChange={handleChange}>
              <Select.Option value={1}>Single</Select.Option>
              <Select.Option value={2}>Double</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="player1" label="Player 1"
            rules={[{ required: true, message: 'Please add first Player' }]}

          >
            <Select>
              {props.players.map(p => (
                <Select.Option value={p.id}>{getFullName(p.name)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="player2" label="Player 2"
            rules={[{ required: size == 1 ? false : true, message: 'Please add second Player' }]}
          >
            <Select disabled={size == 1}>
              {props.players.map(p => (
                <Select.Option value={p.id}>{getFullName(p.name)}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label="Checked In" name="checked_in"
            initialValue={moment()}
          >
            <DatePicker defaultValue={moment()} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item label="Checked Out" name="checked_out"
            initialValue={moment()}
          >
            <DatePicker defaultValue={moment()} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button htmlType={'submit'} type="primary" loading={props.loading}>
          Add Accomodation
        </Button>
      </Form.Item>
    </Form>
  );
};

const AccomodationTable = (props: IAccProps) => {
  useEffect(() => { }, []);

  const columns: ColumnType<IData>[] = [
    {
      title: 'Room Type',
      dataIndex: 'size',
      key: 'size',
      render: (d: number) => (d == 1 ? <div>Single</div> : <div>Double</div>)
    },
    {
      title: 'Player 1',
      dataIndex: 'player1',
      key: 'player_1',
      render: d => [d?.first_name, !d?.last_name?.includes("undefined") ? d?.last_name : ""].join(' ')
    },
    {
      title: 'Player 2',
      dataIndex: 'player2',
      render: d => [d?.first_name, !d?.last_name?.includes("undefined") ? d?.last_name : ""].join(' '),
      key: 'player_2'
    },
    {
      title: 'Check In',
      dataIndex: 'checked_in',
      key: 'checked_in',
      render: (d: any) => moment(d).format('YYYY/MM/DD')
    },
    {
      title: 'Check Out',
      dataIndex: 'checked_out',
      key: 'checked_out',
      render: (d: any) => moment(d).format('YYYY/MM/DD')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button type="primary" onClick={() => props.onUpdate(record.id)}>Edit</Button>
          <Button onClick={() => props.onDelete(record.id)} style={{ background: 'red', color: 'white', borderColor: 'red' }}>Delete</Button>
          {/* <Tag title ={record?.is_paid>0?"paid":"complementary"} color={record.is_paid>0?"red":"geekblue"}>{record?.is_paid>0?"paid":"complementary"}</Tag> */}
        </Space>
      )
    }
  ];
  return <Table dataSource={props.data} columns={columns} />;
};
